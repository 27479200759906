import { Phone } from 'lucide-react';

export default function Header() {
  return (
    <header className="header">
      <h1 className="header-title">Каталог пиломатериалов</h1>
      <div className="phone-container">
        <Phone className="phone-icon" />
        <span className="phone-number">+7 (928) 12-326-80</span>
      </div>
    </header>
  );
}