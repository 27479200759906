import { useState, useEffect } from 'react';

export default function InstallPrompt() {
  const [showIOSPrompt, setShowIOSPrompt] = useState(false);
  const [showAndroidPrompt, setShowAndroidPrompt] = useState(false);

  useEffect(() => {
    // Определяем iOS
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    
    // Определяем, было ли приложение установлено
    const isStandalone = window.matchMedia('(display-mode: standalone)').matches;

    if (isIOS && !isStandalone) {
      setShowIOSPrompt(true);
    }

    // Для Android и других платформ
    window.addEventListener('beforeinstallprompt', (e) => {
      console.log('beforeinstallprompt fired');
      e.preventDefault();
      setShowAndroidPrompt(true);
      
      // Показываем стандартный промпт установки при клике
      const installButton = document.getElementById('install-button');
      if (installButton) {
        installButton.onclick = async () => {
          setShowAndroidPrompt(false);
          (e as any).prompt();
          const result = await (e as any).userChoice;
          console.log('User choice:', result.outcome);
        };
      }
    });
  }, []);

  if (!showIOSPrompt && !showAndroidPrompt) return null;

  return (
    <div className="install-prompt">
      {showIOSPrompt && (
        <div className="prompt-content">
          <p>Установите приложение Мишлен:</p>
          <ol>
            <li>Нажмите кнопку «Поделиться» <span>⎋</span></li>
            <li>Выберите «На экран «Домой»</li>
          </ol>
          <button onClick={() => setShowIOSPrompt(false)} className="close-button">
            Закрыть
          </button>
        </div>
      )}
      {showAndroidPrompt && (
        <div className="prompt-content">
          <p>Установите приложение Мишлен для быстрого доступа</p>
          <button id="install-button" className="install-button">
            Установить
          </button>
          <button onClick={() => setShowAndroidPrompt(false)} className="close-button">
            Закрыть
          </button>
        </div>
      )}
    </div>
  );
} 