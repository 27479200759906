import React, { useEffect, useState } from 'react';
import Header from './components/Header';
import ProductList from './components/ProductList';
import { Product } from './types';
import { fetchProducts } from './api';
import InstallPrompt from './components/InstallPrompt';

const App: React.FC = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadProducts() {
      const data = await fetchProducts();
      setProducts(data);
      setLoading(false);
    }
    loadProducts();
  }, []);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-8">
        {loading ? (
          <div className="text-center">Загрузка...</div>
        ) : (
          <ProductList products={products} />
        )}
      </main>
      <InstallPrompt />
    </div>
  );
};

export default App;