import { Product } from './types';

export async function fetchProducts(): Promise<Product[]> {
  try {
    const response = await fetch('/products.json');
    const data = await response.json();
    return data.map((item: any) => ({
      id: Math.random(),
      name: item.product_name,
      volumeInCubes: item.available_cubes,
      quantityInPieces: item.available_pieces,
      buyPricePerCube: item.price_for_cube_in_buy,
      sellPricePerCube: item.price_for_cube_when_sold,
      totalBuyPrice: item.full_price_when_bought,
      companyName: item.company_name
    }));
  } catch (error) {
    console.error('Error fetching products:', error);
    return [];
  }
}