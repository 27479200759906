import { useState } from 'react';
import { Product } from '../types';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface ProductListProps {
  products: Product[];
}

export default function ProductList({ products }: ProductListProps) {
  const [expandedProduct, setExpandedProduct] = useState<number | null>(null);

  const toggleProduct = (id: number) => {
    setExpandedProduct(expandedProduct === id ? null : id);
  };

  return (
    <div className="product-list">
      {products.map((product) => (
        <div key={product.id} className="product-card">
          <div 
            className="product-header"
            onClick={() => toggleProduct(product.id)}
          >
            <div>
              <h2 className="product-title">{product.name}</h2>
              <p className="product-company">{product.companyName}</p>
            </div>
            {expandedProduct === product.id ? <ChevronUp /> : <ChevronDown />}
          </div>
          {expandedProduct === product.id && (
            <div className="product-details">
              <div className="product-grid">
                <p><span className="label">Объем:</span> {product.volumeInCubes} м³</p>
                <p><span className="label">Количество:</span> {product.quantityInPieces} шт.</p>
                <p><span className="label">Цена продажи:</span> {product.sellPricePerCube} ₽/м³</p>
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
}